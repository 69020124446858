import request from '@/utils/request'

/**
 * 微页面模块接口
 */

//列表
export const getPagedList = (data) => request('/admin/tinyPage/getPagedList', data);
//详情
export const getPageDetail = (data) => request('/admin/tinyPage/detail', data);
//添加
export const getPageAdd = (data) => request('/admin/tinyPage/add', data);
//编辑
export const getPageEdit = (data) => request('/admin/tinyPage/edit', data);
//删除
export const getPageBatchDel = (data) => request('/admin/tinyPage/batchDel', data);
//设置为活动页
export const updateType = (data) => request('/admin/tinyPage/updateType', data);