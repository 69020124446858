<template>
	<div class="mallPagemange">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">页面名称: </label>
				<el-input v-model="pageName" placeholder="请输入页面名称" maxlength="20" style="width: 300px;"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
                <buttonPermissions :datas="'addPage'">
					<el-button type="primary" style="margin-left:30px" @click="addFright({})">添加</el-button>
				</buttonPermissions>
				
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="pageData" style="width: 100%;" v-loading="loading" ref="multipleTable">
                <el-table-column prop="id" label="id" width="80"></el-table-column>
				<el-table-column prop="Name" label="页面名称">
					<template slot-scope="scope">
						<span style="padding-right: 10px;">{{scope.row.pageName}}</span>
                        <el-tag v-if="scope.row.type">活动页</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="260">
					<template slot-scope="scope">
                        <buttonPermissions :datas="'mallPageDetails'">
                            <el-button type="text" @click="addFright(scope.row)">编辑</el-button>
                        </buttonPermissions>
                        <buttonPermissions :datas="'delPage'">
                            <span style="color:#f00;margin-left:10px;cursor: pointer;" v-if="!scope.row.IsIndexPage" @click="deleteChose(scope.row)">删除</span>
                        </buttonPermissions>
						<buttonPermissions :datas="'mallPageDetails'" v-if="!scope.row.type">
                            <el-button type="text" style="margin-left:10px;" @click="changeActivity(scope.row)">设为活动页</el-button>
                        </buttonPermissions>
						
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination
					v-if="total"
					style="margin:20px 0;float:right;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageNo"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		getPagedList,
		getPageBatchDel,
        updateType
	} from '@/api/cms';
	import batchCods from "@/components/bathCodes"
	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		data() {
			return {
				pageName: '',
				pageData: [],
				pageNo: 1,
				pageSize: 20,
				total: 0,
				loading: false,
				activeName: 'shop'
			}
		},
		computed: {
			
		},
		components: {
			batchCods,
			buttonPermissions
		},
		beforeMount() {
			this.getList();
		},
		methods: {
			handleFilter() {
				this.pageNo = 1;
				this.getList();
			},
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
                        pageNo:this.pageNo,
                        pageSize:this.pageSize,
                        pageName:this.pageName
					}

					let res = await getPagedList(data);

					this.total = res.data.total;
					this.pageData = res.data.records || [];

				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},

			// 编辑和新增
			addFright(record) {
                let params = {
                    id: record.id
                }
                this.publicToDetailsR(params,'/mallPage/mallPageDetails',this);
			},

			// 删除
			async deleteChose(record) {
                let _this = this;
				this.delteLoading = true
				this.$confirm('是否确认确认删除页面?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let res = await getPageBatchDel([record.id]);
					if (res.success) {
						_this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						_this.handleFilter()
					}
				})
			},

			// 切换显示条数
            handleSizeChange (val) {
                this.pageSize = val;
                this.getList();
            },
            // 翻页
            handleCurrentChange (val) {
                this.pageNo = val;
                this.getList();
            },
            //设置为活动页
            changeActivity(row){
                let _this = this;
                this.$confirm('是否将当前页面设置为活动页?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let res = await updateType({id:row.id});
					if (res.success) {
						_this.$message({showClose: true,
							type: 'success',
							message: '设置成功!'
						});
						_this.handleFilter()
					}
				})

            }
		}
	}
</script>

<style lang="less" scoped>
	.mallPagemange {
		width: 100%;
		background: #fff;
		padding: 15px;
	}
</style>
